<template>
    <div class="container h-100">
 
     
 
       <div class="container py-5 h-100">
        <div class="d-flex justify-content-end align-items-center">
           <h5 class="m-0 p-0">Already have an account?</h5>
           <router-link :to="{ name: 'Login', query: $route.query}"><span class="auth_nav_btn ms-2">Log in</span></router-link>
        </div>

         <div class="d-flex justify-content-center align-items-center h-100 flex-column">
 
          <div class="d-flex">
 
             <div class="d-flex me-5" v-if="this.rldata.screen_flags['remember_info_screen']">
                <div class="card-body">
                   <div>
                         <p class="m-0 p-0 mb-4" style="font-weight: 500; font-size: 22px; text-align:center;">EO Space account details</p>
 
                         <div style="background: #FFFFFF; border-radius: 12px; padding: 16px;">
                             <div class="d-flex justify-content-between">
                                 <p style="font-size: 14px;">Username</p><p><b>{{rldata.rand_child_data.username}}</b></p>
                             </div>
                             <div class="d-flex justify-content-between mb-3">
                                 <p style="font-size: 14px;">Password </p><p><b>{{rldata.rand_child_data.raw_password}}</b></p>
                             </div>
 
                             <div style="background: #FDF4D6; border-radius: 8px; padding: 8px 16px;">
                                 <p class="m-0 p-0"><i class="mdi mdi-alert" style="color: coral;"></i> This is your automatically generated account details.<br>It is important to save this information for login purposes.<br>Please, write it down or copy securely. </p>
                             </div>
                         </div> 
 
                         <button @click="child_signup_form__rand_data" class="btn mt-5" style="background: #55D18E; box-shadow: 0px 4px 0px #4FBC81; border-radius: 12px; color: white; width: 100%;">Next</button>
                         <p class="text-center my-3">or</p>
                         <button @click="show__enter_your_data_screen" class="btn" style="background: #FFFFFF; box-shadow: 0px 4px 0px #DEDEDE; border-radius: 12px; color: #2E3B4B; width: 100%;">Sign Up with my own details</button>

                    </div>
                </div>
             </div>



             <div class="card-body" v-if="this.rldata.screen_flags['enter_your_data_screen']">

                <p @click="hide__enter_your_data_screen" style="font-weight: 500; font-size: 20px; text-align: left; cursor: pointer;"><i class="mdi mdi-arrow-left-thick"></i> Go Back</p>
 
                <div class="d-flex flex-column">
 
                 <div>
                     <form @submit.prevent="child_signup_form">
                         <div class="form-group mb-3">
                             <input style="border: 2px solid #DCE0EA; border-radius: 12px;" placeholder="First name" type="text" class="form-control" id="inputFirstName" required v-model="rldata.new_child.first_name">
                         </div>
                         <div class="form-group mb-4">
                             <input maxlength="1" style="border: 2px solid #DCE0EA; border-radius: 12px;" placeholder="First letter of your last name" type="text" class="form-control" id="inputInitial" required v-model="rldata.new_child.initial">
                         </div>
                         <div class="form-group mb-3 text-end">
                             <div class="input-group" id="show_hide_password">
                                 <input v-if="rldata.showPassword" style="border: 2px solid #DCE0EA; border-radius: 12px 0px 0px 12px;" placeholder="Create password*" aria-describedby="passwordHelp" class="form-control" type="text" required v-model="rldata.new_child.password">
                                 <input v-else style="border: 2px solid #DCE0EA; border-radius: 12px 0px 0px 12px;" placeholder="Create password*" aria-describedby="passwordHelp" class="form-control" type="password" required v-model="rldata.new_child.password">
                                 <div class="input-group-append">
                                     <span @click="toggleShowPassword" class="input-group-text h-100"><i :class="{ 'fa fa-eye-slash': !rldata.showPassword, 'fa fa-eye': rldata.showPassword }" aria-hidden="true"></i></span>
                                 </div>
                             </div>
                             <small id="passwordHelp" class="form-text text-muted">*must contain letters and at least one number</small>
                         </div>
 
                         
 
                         <button type="submit" class="mb-3 btn w-100 auth_submit_btn">Sign up</button>
 
                         <div class="form-check mb-3 d-flex justify-content-center">
                             <input type="checkbox" class="form-check-input" id="termsCheck" required v-model="rldata.new_child.terms_check">
                             <label class="form-check-label ms-2" for="termsCheck">By registering, you agree with the <br> <a href="https://eo-space.com/terms" target="_blank">Terms of Use</a> and <a href="https://eo-space.com/privacy" target="_blank">Privacy Policy</a></label>
                         </div>
 
                     </form>
                 </div>
 
                </div>
             </div>



 
          </div>
         </div>
 
       </div>
    </div>
 </template>
 
 <script>
 fbq('track', 'ViewContent');
 
 var rldata = {
        rand_child_data:  {
             username: '',
             raw_password: '',
        },
 
        new_child: {
            first_name: '',
            initial: '',
            password: '',
            terms_check: true
        },

        utm_data_reg: '',

        showPassword: false,

        screen_flags: {
            remember_info_screen: false,
            enter_your_data_screen: false,
        },
 }
 export default {
   name: "Signup",
   data () {
     return {
       rldata
     }
   },
   methods: {

    toggleShowPassword() {
        this.rldata.showPassword = !this.rldata.showPassword;
     },

    show__enter_your_data_screen: function() {
        this.rldata.screen_flags.remember_info_screen = false;
        this.rldata.screen_flags.enter_your_data_screen = true;
    },

    hide__enter_your_data_screen: function() {
        this.rldata.screen_flags.enter_your_data_screen = false;
        this.rldata.screen_flags.remember_info_screen = true;
    },

    get_rand_child_data: function () {
            let cute_nicknames = [
                'angel',
                'diamond',
                'baby',
                'fleur',
                'fluffy',
                'bambi',
                'cesar',
                'princess'
            ];

            let cute_beeings = [
                'elephant',
                'bunny',
                'hedgehog',
                'fox',
                'penguin',
                'quokka',
                'giraffe',
                'lemur',
                'seal'
            ];

            let randIndex__cute_nicknames = Math.floor(Math.random() * 8);
            let randIndex__cute_beeings = Math.floor(Math.random() * 9);

            let new_username = cute_nicknames[randIndex__cute_nicknames] + '_' + cute_beeings[randIndex__cute_beeings];
            let new_password = Math.random().toString(36).substr(2, 8);
            
            new_username = new_username.replace(/\s+/g, '');
            new_username = new_username.replace(/\'+/g, '');
            new_username = new_username.replace(/-+/g, '');
            new_username = new_username.toLowerCase();
             
             
             let data = {
               username: new_username
             };
             
             this.$root.child__check_login_availability(data, this.rldata);

             this.rldata.rand_child_data.raw_password = new_password;
     },

     child_signup_form__rand_data: function () {
             let username = this.rldata.rand_child_data.username;
             
             let data = {
               first_name: "",
               initial: "",
               username,
               password: this.rldata.rand_child_data.raw_password,
               adult_connected: false,
               utm_data: this.rldata.utm_data_reg
             };
             
             this.$root.add_new_child__rand_data(data);

     },

     child_signup_form: function () {
         if (this.rldata.new_child['first_name'].length > 0 && this.rldata.new_child['initial'].length == 1 && this.rldata.new_child['password'].length > 0 && this.rldata.new_child['terms_check']) {
            
            if(!/^[A-Za-z]+$/.test(this.rldata.new_child['first_name'])){
                alert("First name must contain only english letters!");
                return;
            }

            if(!/^[A-Za-z]+$/.test(this.rldata.new_child['initial'])){
                alert("Initial must contain only english letter!");
                return;
            }

            var regularExpression = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,24}$/;

            if(!regularExpression.test(this.rldata.new_child['password'])){
                alert("Password must be > 6 and < 24, and contain at least one number!");
                return;
            }

             let username = this.rldata.new_child.first_name + this.rldata.new_child.initial;
             username = username.replace(/\s+/g, '');
             username = username.replace(/\'+/g, '');
             username = username.replace(/-+/g, '');
             username = username.toLowerCase();
             
             
             let data = {
               first_name: this.rldata.new_child['first_name'],
               initial: this.rldata.new_child['initial'],
               username,
               password: this.rldata.new_child['password'],
               adult_connected: false,
               utm_data: this.rldata.utm_data_reg
             };
             // console.log(data);
             this.$root.add_new_child(data);
 
         } else {
             alert('not all fields filled correctly')
         }
     },
   },
    mounted () {
            this.get_rand_child_data();

            if(this.$route.query.utm_source) { this.rldata.utm_data_reg = this.$route.query.utm_source; }

    },
     beforeMount() {
       var cleanData = {
        rand_child_data: {
             username: '',
             raw_password: '',
         },

        new_child: {
            first_name: '',
            initial: '',
            password: '',
            terms_check: true
        },

        utm_data_reg: '',

        showPassword: false,
 
         screen_flags: {
             remember_info_screen: false,
             enter_your_data_screen: false,
         },
     };
       this.rldata = cleanData;
    },
    watch: {
        "rldata.rand_child_data.username": function(handler) {
            if(handler){
                this.rldata.screen_flags.remember_info_screen = true;
            } 
        }
   }
 }
 </script>